/* global site */
import ('./site/scroll-to.js');
import ('./site/side-panel.js');
import ('./site/admin-forms.js');
import ('./site/tracking.js');

window.site = (window.site || {});

/**
 * Main application class.
 * @class App
 * @static
 */
window.site.App = (function App() {
  /**
   * Has the class been initialized?
   * @private
   */
  let inited = false;

  /**
   * Application config defaults.
   * @private
   * @param config.env         Current server environment
   * @param config.csrf        Security token to submit with forms
   * @param config.csrfName    Security token to submit with forms
   * @param config.locale      Current locale short code
   * @param config.device      Device detection based on browser signature
   * @param config.preview     Page is shown through live preview mode
   * @param config.general     Settings from general config
   */
  const config = {
    env: 'production',
    csrf: null,
    csrfName: null,
    locale: 'en',
    device: 'desktop',
    preview: false,
    general: {},
  };

  /**
   * Initializes the class.
   * @public
   */
  const init = function init(options) {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    // Store application settings
    options = (options || {});

    if (options.env) { config.env = options.env; }
    if (options.csrf) { config.csrf = options.csrf; }
    if (options.csrfName) { config.csrfName = options.csrfName; }
    if (options.locale) { config.locale = options.locale; }
    if (options.device) { config.device = options.device; }
    if (options.preview) { config.preview = options.preview; }
    if (options.general) { config.general = options.general; }

    // Global
    // Detect css-grid un-supported
    const el = document.createElement("div")
    const supportsGrid = "string" == typeof el.style.grid;

    if(!supportsGrid) {
      const body = document.getElementsByTagName("body")[0].classList.add('is-ie-11');
      return false;
    }

    // IE 11 || Old Browser - Ignore zone
    // console.table(options);

    // Register ServiceWorker
    if ('serviceWorker' in navigator) {
      // console.log('Registering ServiceWorker...');
      // window.addEventListener('load', function() {
      //     navigator.serviceWorker.register('/sw.js', {
      //         scope: "/"
      //     }).then(function(registration) {
      //         // Registration was successful
      //         console.log('ServiceWorker registration successful with scope: ', registration.scope);
      //         // Trim the caches on load
      //         navigator.serviceWorker.controller && navigator.serviceWorker.controller.postMessage({
      //             command: "trimCaches"
      //         });
      //    }).catch(function(err) {
      //         // registration failed :(
      //         console.log('ServiceWorker registration failed: ', err);
      //     });
      // });
    }

    // PreRender for fast website
    function prerenderlink(e) {
      // console.log(e, e.currentTarget, e.currentTarget.href);
      var head = document.getElementsByTagName("head")[0];
      var refs = head.childNodes;
      var ref = refs[ refs.length - 1];

      var elements = head.getElementsByTagName("link");
      Array.prototype.forEach.call(elements, function(el, i) {
        if (("rel" in el) && (el.rel === "prerender"))
          el.parentNode.removeChild(el);
      });

      var prerenderTag = document.createElement("link");
      prerenderTag.rel = "prerender";
      prerenderTag.href = e.currentTarget.href;
      ref.parentNode.insertBefore(prerenderTag,  ref);
    }

    const $prerenderLinks = document.querySelectorAll('#page-header a');
    [].slice.call($prerenderLinks).forEach(function(elem) {
      elem.addEventListener('mouseenter', prerenderlink);
    });

    var lightboxVideo = GLightbox({
      selector: '.glightbox3',
      touchNavigation: true,
      loop: true,
      autoplayVideos: true
    });

    lightboxVideo.on('slide_changed', function({prev, current}) { 
      var player = current.player
      player.on('ended', function(event) {
        lightboxVideo.nextSlide(); // Go to next slide
      });
    })

    if (!window.matchMedia('(max-width: 955px)').matches) {
      //Scroll fiche emploi
      function jobscroll() {
        var screen = window.innerHeight;
        var emploisdetails = document.querySelector('.emploi-details').offsetHeight + 75;
        var pos = window.scrollY + screen - emploisdetails;

        if (pos > 0) {
          document.querySelector('.emploi-details').classList.remove('fixed');
        } else {
          document.querySelector('.emploi-details').classList.add('fixed');
        }
      }

      if (document.querySelector('.emploi-details')) {
        jobscroll();
        window.onscroll = function() {
          jobscroll();
        };
      }
    }

    var filterEntrepriseUrl = '/entreprises/';
    var filterEmploisUrl = '/emplois/';

    if (document.querySelector('html').getAttribute('lang') == 'en') {
      filterEntrepriseUrl = '/en/business/';
      filterEmploisUrl = '/en/jobs/';
    }

    [].slice.call(document.querySelectorAll('.select-entreprises')).forEach(function(elem) {
      elem.addEventListener('change', function(e) {
        e.preventDefault();

        // Only auto filter on Mobile
        if (!window.matchMedia('(max-width: 955px)').matches) {
          return;
        }

        var secteurSlug = document.querySelector('#select-secteur').value ? document.querySelector('#select-secteur').value : 'all';
        var avantagesSlug = document.querySelector('#select-avantages').value ? document.querySelector('#select-avantages').value : 'all';

        window.location.href = filterEntrepriseUrl + secteurSlug + '/' + avantagesSlug

      });
    });

    if (document.querySelector('.filters-button-entreprises')) {
      document.querySelector('.filters-button-entreprises').addEventListener('click', function (e) {
        e.preventDefault();

        var secteurSlug = document.querySelector('#select-secteur').value ? document.querySelector('#select-secteur').value : 'all';
        var avantagesSlug = document.querySelector('#select-avantages').value ? document.querySelector('#select-avantages').value : 'all';

        window.location.href = filterEntrepriseUrl + secteurSlug + '/' + avantagesSlug
      });
    }

    [].slice.call(document.querySelectorAll('.select-emplois')).forEach(function(elem) {
      elem.addEventListener('change', function(e) {
        e.preventDefault();

        // Only auto filter on Mobile
        if (!window.matchMedia('(max-width: 955px)').matches) {
          return;
        }

        var secteurSlug = document.querySelector('#select-secteur').value ? document.querySelector('#select-secteur').value : 'all';
        var typeSlug = document.querySelector('#select-type').value ? document.querySelector('#select-type').value : 'all';
        var horaireSlug = document.querySelector('#select-horaire').value ? document.querySelector('#select-horaire').value : 'all';

        window.location.href = filterEmploisUrl + secteurSlug + '/' + typeSlug + '/' + horaireSlug

      });
    });

    if (document.querySelector('.filters-button-emplois')) {
      document.querySelector('.filters-button-emplois').addEventListener('click', function (e) {
        e.preventDefault();

        var secteurSlug = document.querySelector('#select-secteur').value ? document.querySelector('#select-secteur').value : 'all';
        var typeSlug = document.querySelector('#select-type').value ? document.querySelector('#select-type').value : 'all';
        var horaireSlug = document.querySelector('#select-horaire').value ? document.querySelector('#select-horaire').value : 'all';

        window.location.href = filterEmploisUrl + secteurSlug + '/' + typeSlug + '/' + horaireSlug
      });
    }

    // Admin Lang traduction switch
    if (document.querySelector('.select-lang-wrap')) {
      document.querySelector('.select-lang-wrap form select').addEventListener('change', function (e) {
        document.querySelector('.select-lang-wrap form').submit();
      });
    }

    // Mobile Menu
    if (document.querySelector('.mobile-menu-trigger')) {
      document.querySelector('.mobile-menu-trigger').addEventListener('click', function(e) {
        e.preventDefault();
        document.querySelector('.navigation-mobile').classList.add('is-active');
        document.querySelector('.navigation-mobile-overlay').classList.add('is-active');
      });

      document.querySelector('.navigation-close').addEventListener('click', function(e) {
        e.preventDefault();
        document.querySelector('.navigation-mobile').classList.remove('is-active');
        document.querySelector('.navigation-mobile-overlay').classList.remove('is-active');
      });

      document.querySelector('.navigation-mobile-overlay').addEventListener('click', function(e) {
        e.preventDefault();
        document.querySelector('.navigation-mobile').classList.remove('is-active');
        document.querySelector('.navigation-mobile-overlay').classList.remove('is-active');
      });
    }

    // on scroll
    window.addEventListener('scroll', function(e) {
      const mainHeader = document.querySelector('.main-header');

      if (window.scrollY > 100) {
        mainHeader.classList.add('is-scrolled');
      } else {
        mainHeader.classList.remove('is-scrolled');
      }
    });

    // Return success
    return true;
  };

  /**
   * Getter for application config.
   * @public
   */
  const getConfig = function getConfig(option) {
    return config[option] ? config[option] : false;
  };

  /**
   * Expose public methods & properties.
   */
  return {
    init,
    config: getConfig,
  };
}());
